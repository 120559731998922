* {
  font-family: 'roboto';
  margin: 0;
  padding: 0;
}

.hbox {
  flex-direction: row;
  display: flex;
  align-items: center;
}

.spacing {
  margin: 16px auto;
}

.input {
  width: 100%;
}

.landing {
  text-align: center;
}

.main-container {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}
.content {
  flex: 1 0 auto;
}
.footer {
  margin-top: 16px;
  padding-bottom: 8px;
  padding-top: 32px;
  flex-shrink: 0;
  background-color: rgba(63, 81, 181, 0.1);
  text-align: center;
}